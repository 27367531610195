import { IPublicClientApplication } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../AuthConfig';
import { Application } from '../models/Application';
import { ApplicationCommentModel } from '../models/ApplicationComment';
import { ApplicationItem } from '../models/ApplicationItem';
import { ApplicationReviewModel } from '../models/ApplicationReviewModel';
import { ApplicationPeriod } from '../models/ApplicationPeriod';
import { Criteria } from '../models/Criteria';
import { Organisation } from '../models/Organisation';
import { OrganisationUser } from '../models/OrganisationUser';
import { getSettings } from '../Settings';
import { handleError } from '../utils/handleError';
import { FileUpload } from '../models/FileUpload';
import { FormModel } from '../components/formbuilder/models/FormModel';
import { ApplicationDetails } from '../models/ApplicationDetails';
import { FormFileUpload } from '../components/formbuilder/models/FormFileUpload';
import { ImportApplicationsRequest } from '../models/ImportApplicationsRequest';
import { Invitation } from '../models/Invitation';
import { FetchForReviewType, PagedResult } from '../models/PagedResult';
import { ReviewablePeriod } from '../models/ReviewablePeriods';
import { Dashboard } from '../models/Dashboard';
import { PeriodApprovalSummary } from '../features/application-approval/application-approval-list/PeriodApprovalSummary';
import { PeriodReviewSummary } from '../features/common/application/PeriodReviewSummary';
import { ApplicationPeriodResult } from '../models/ApplicationPeriodResult';
import { UserSession } from '../models/UserSession';
import { AgreementItem } from '../features/agreement/Agreement';
import { BankAccount } from '../models/BankAccount';
import { ApplicationStatus } from '../models/ApplicationStatus';

class Api {
  private static baseUrl: string = getSettings().SERVER_URL;

  private instance: IPublicClientApplication;

  constructor(instance: IPublicClientApplication) {
    this.instance = instance;
  }

  public async importSingelApplicationCreate(
    organisationId: string,
    applicationPeriodId: string,
  ): Promise<ApplicationDetails> {
    const response = await this.post(`/organisations/${organisationId}/imports/single`, {
      applicationPeriodId: applicationPeriodId,
    });

    return response.json();
  }

  public async importSingleApplicationSubmit(
    organisationId: string,
    applicationId: string,
    model: FormModel,
  ): Promise<Response> {
    const response = await this.put(
      `/organisations/${organisationId}/imports/single/${applicationId}`,
      {
        json: model,
      },
    );

    return response;
  }

  public async importApplications(
    organisationId: string,
    request: ImportApplicationsRequest,
  ): Promise<Response> {
    return this.post(`/organisations/${organisationId}/imports/multi`, request);
  }

  public uploadLogotype = async (organisationId: string, fileUpload: FileUpload) => {
    const response = await this.postFile(`/organisations/${organisationId}/logotype/`, fileUpload);

    fileUpload.uploadComplete();

    return response;
  };

  public uploadFile = async (
    organisationId: string,
    applicationId: string,
    fileUpload: FormFileUpload,
  ) => {
    const { elementId } = fileUpload;
    const response = await this.postFile(
      `/organisations/${organisationId}/imports/${applicationId}/upload/${elementId}`,
      fileUpload,
    );

    fileUpload.uploadComplete();

    return response;
  };

  public deleteUploadedFile = async (
    organisationId: string,
    applicationId: string,
    elementId: string,
  ) => {
    const response = await this.delete(
      `/organisations/${organisationId}/imports/${applicationId}/upload/${elementId}`,
    );

    return response;
  };

  public getReviewBudgetAndSuggested = async (
    organisationId: string,
    applicationId: string,
  ): Promise<PeriodReviewSummary> =>
    this.get<PeriodReviewSummary>(
      `/organisations/${organisationId}/applications/review/${applicationId}/budget-and-suggested`,
    );

  public getReviewBudgetAndSuggestedAllPeriods = async (
    organisationId: string,
  ): Promise<Array<PeriodReviewSummary>> =>
    this.get(`/organisations/${organisationId}/applications/review/budget-and-suggested`);

  public getReviewablePeriods = async (organisationId: string): Promise<ReviewablePeriod[]> =>
    this.get(`/organisations/${organisationId}/applications/review/open-for-review`);

  public getApplicationCounters = async (organisationId: string): Promise<any> =>
    this.get(`/organisations/${organisationId}/applications/counters`);

  public getApplicationsForReview = async (
    organisationId: string,
    fetchType: FetchForReviewType,
    periodId?: string | null,
    pageNumber?: number,
  ): Promise<PagedResult<ApplicationItem>> => {
    const fType =
      fetchType === FetchForReviewType.ReviewTodo
        ? 'todo'
        : fetchType === FetchForReviewType.ReviewCompleted
          ? 'completed'
          : fetchType === FetchForReviewType.ReviewDeleted
            ? 'deleted'
            : 'all';

    let path = `/organisations/${organisationId}/applications/review/${fType}`;

    if (periodId || pageNumber) {
      const queryParams = Array<string>();

      if (pageNumber) {
        queryParams.push(`pageNumber=${pageNumber}`);
      }

      if (periodId) {
        queryParams.push(`periodId=${periodId}`);
      }

      path = `${path}?${queryParams.join('&')}`;
    }

    return this.getWithPagination(path);
  };

  async getApplications(
    organisationId: string,
    status?: ApplicationStatus | null,
    keyword?: string | null,
    periodId?: string | null,
  ): Promise<any> {
    let path = `/organisations/${organisationId}/applications`;
    if (periodId || status || keyword) {
      const queryParams = Array<string>();

      if (periodId) {
        queryParams.push(`applicationPeriodId=${periodId}`);
      }

      if (status) {
        queryParams.push(`applicationStatus=${status}`);
      }

      if (keyword) {
        queryParams.push(`keyword=${keyword}`);
      }

      path = `${path}?${queryParams.join('&')}`;
    }

    return this.get<any>(path);
  }

  async getHistoryByApplication(organisationId: string, applicationId: string): Promise<any[]> {
    return this.get<any[]>(
      `/organisations/${organisationId}/applications/history/${applicationId}`,
    );
  }

  public async getApplication(
    organisationId: string,
    id: any,
    filterOnPeriod?: boolean,
    filterOnType?: FetchForReviewType,
  ): Promise<Application> {
    let path = `/organisations/${organisationId}/applications/${id}`;

    if (filterOnPeriod !== undefined || filterOnType !== undefined) {
      const queryParams = Array<string>();

      if (filterOnPeriod !== undefined) {
        queryParams.push(`filterOnPeriod=${filterOnPeriod}`);
      }

      if (filterOnType !== undefined) {
        queryParams.push(`filterType=${filterOnType}`);
      }

      path = `${path}?${queryParams.join('&')}`;
    }

    return this.get<Application>(path);
  }

  public async getApprovalPeriods(organisationId: string): Promise<any[]> {
    return this.get<any>(`/organisations/${organisationId}/approvals`);
  }

  public async getApprovalPeriod(
    organisationId: string,
    applicationPeriodId: string,
  ): Promise<PeriodApprovalSummary> {
    return this.get<any>(`/organisations/${organisationId}/approvals/${applicationPeriodId}`);
  }

  public async getApprovalPeriodReviewers(
    organisationId: string,
    applicationPeriodId: string,
  ): Promise<any[]> {
    return this.get<any>(
      `/organisations/${organisationId}/approvals/${applicationPeriodId}/reviewers`,
    );
  }

  public async getApplicationsForApproval(
    organisationId: string,
    applicationPeriodId: string,
    reviewerOrganisationUserId?: string | null,
  ): Promise<any[]> {
    const queryString = reviewerOrganisationUserId
      ? `?organisationUserId=${reviewerOrganisationUserId}`
      : '';

    return this.get<any>(
      `/organisations/${organisationId}/approvals/${applicationPeriodId}/applications${queryString}`,
    );
  }

  public async getArchivedApplicationPeriods(organisationId: string): Promise<any[]> {
    return this.get<any[]>(`/organisations/${organisationId}/archive`);
  }

  public async getArchivedApplicationPeriod(
    organisationId: string,
    applicationPeriodId: string,
  ): Promise<any> {
    return this.get<any>(`/organisations/${organisationId}/archive/${applicationPeriodId}`);
  }

  public async getArchivedApplications(
    organisationId: string,
    applicationPeriodId: string,
  ): Promise<any[]> {
    return this.get<any[]>(
      `/organisations/${organisationId}/archive/${applicationPeriodId}/applications`,
    );
  }

  public async search(organisationId: string, keyword: string): Promise<any[]> {
    return this.get<any[]>(`/organisations/${organisationId}/search?keyword=${keyword}`);
  }

  // public async getArchivedApplication(
  //   organisationId: string,
  //   id: string,
  // ): Promise<ApplicationApprovalModel> {
  //   return this.get<ApplicationApprovalModel>(
  //     `/organisations/${organisationId}/archive/applications/${id}`,
  //   );
  // }

  // public async getApplicationForApproval(
  //   organisationId: string,
  //   id: any,
  // ): Promise<ApplicationApprovalModel> {
  //   return this.get<ApplicationApprovalModel>(
  //     `/organisations/${organisationId}/approvals/applications/${id}`,
  //   );
  // }

  public updateApplicationDecision = async (
    organisationId: string,
    applicationId: string,
    decision: any,
  ): Promise<Response> =>
    this.put(`/organisations/${organisationId}/approvals/applications/${applicationId}`, decision);

  public async getApplicationComments(
    organisationId: string,
    applicationId: any,
  ): Promise<ApplicationCommentModel[]> {
    return this.get<ApplicationCommentModel[]>(
      `/organisations/${organisationId}/applications/${applicationId}/comments`,
    );
  }

  public async getApplicationReviews(
    organisationId: string,
    id: any,
  ): Promise<ApplicationReviewModel[]> {
    return this.get<ApplicationReviewModel[]>(
      `/organisations/${organisationId}/applicationreviews/${id}`,
    );
  }

  public async getApplicationReviewForUser(
    organisationId: string,
    applicationId: any,
  ): Promise<ApplicationReviewModel> {
    return this.get<ApplicationReviewModel>(
      `/organisations/${organisationId}/applicationreviews/${applicationId}/user`,
    );
  }

  public updateApplicationReview = async (
    organisationId: string,
    applicationId: string,
    review: any,
  ): Promise<Response> =>
    this.put(`/organisations/${organisationId}/applicationreviews/${applicationId}`, review);

  public notifyApplicant = async (
    organisationId: string,
    applicationId: string,
  ): Promise<Response> => {
    return this.post(
      `/organisations/${organisationId}/applications/${applicationId}/notifications`,
      {},
    );
  };

  public requestCompletion = async (
    organisationId: string,
    applicationId: string,
    comment: string,
  ): Promise<Response> => {
    return this.post(`/organisations/${organisationId}/applications/${applicationId}/completion`, {
      comment,
    });
  };

  public getInvitation = async (key: string): Promise<Invitation> =>
    this.getUnauthenticated<Invitation>(`/invitations/${key}`);

  public async acceptInvitation(
    invitationKey: string,
    firstname: string,
    lastname: string,
    password: string,
  ): Promise<Response> {
    return this.postUnauthenticated('/invitations', {
      invitationKey,
      firstname,
      lastname,
      password,
    });
  }

  public getApplicationPeriodForm = async (
    organisationId: string,
    applicationPeriodId: string,
  ): Promise<FormModel> =>
    this.get<any>(
      `/organisations/${organisationId}/applicationperiods/${applicationPeriodId}/form`,
    );

  public updateApplicationPeriodForm = async (
    organisationId: string,
    applicationPeriodId: string,
    form: any,
  ): Promise<Response> =>
    this.put(
      `/organisations/${organisationId}/applicationperiods/${applicationPeriodId}/form`,
      form,
    );

  public async createApplicationComment(
    organisationId: string,
    applicationId: string,
    text: string,
  ): Promise<Response> {
    return this.post(`/organisations/${organisationId}/applications/${applicationId}/comments`, {
      text,
    });
  }

  public async getCriterias(organisationId: string): Promise<Criteria[]> {
    return this.get<Criteria[]>(`/organisations/${organisationId}/criterias`);
  }

  public async updateCriteria(
    organisationId: string,
    criteriaId: string,
    title: string,
    weight: number,
  ): Promise<Response> {
    return this.put(`/organisations/${organisationId}/criterias/${criteriaId}`, { title, weight });
  }

  public async createCriteria(
    organisationId: string,
    title: string,
    weight: number,
  ): Promise<Response> {
    return this.post(`/organisations/${organisationId}/criterias`, { title, weight });
  }

  public async deleteCriteria(organisationId: string, criteriaId: string): Promise<Response> {
    return this.delete(`/organisations/${organisationId}/criterias/${criteriaId}`);
  }

  public async getDashboard(organisationId: string): Promise<Dashboard> {
    return this.get<Dashboard>(`/organisations/${organisationId}/dashboard`);
  }

  public async getApplicationReports(organisationId: string): Promise<any[]> {
    return this.get<any[]>(`/organisations/${organisationId}/applicationreports`);
  }

  public async getApplicationPeriods(organisationId: string): Promise<ApplicationPeriodResult[]> {
    return this.get<ApplicationPeriodResult[]>(
      `/organisations/${organisationId}/applicationperiods`,
    );
  }

  public async getApplicationPeriod(organisationId: string, id: any): Promise<ApplicationPeriod> {
    return this.get<ApplicationPeriod>(`/organisations/${organisationId}/applicationperiods/${id}`);
  }

  public async createApplicationPeriod(
    organisationId: string,
    applicationPeriod: ApplicationPeriod,
  ): Promise<Response> {
    return this.post(`/organisations/${organisationId}/applicationperiods`, applicationPeriod);
  }

  public async copyApplicationPeriod(
    organisationId: string,
    copyPeriodId: string,
  ): Promise<Response> {
    const copyInformation = { copyPeriodId: copyPeriodId };
    return this.post(`/organisations/${organisationId}/applicationperiods/copy`, copyInformation);
  }

  public async deleteApplicationPeriod(
    organisationId: string,
    applicationPeriodId: string,
  ): Promise<Response> {
    return this.delete(
      `/organisations/${organisationId}/applicationperiods/${applicationPeriodId}`,
    );
  }

  public async updateApplicationPeriod(
    organisationId: string,
    applicationPeriod: ApplicationPeriod,
  ): Promise<Response> {
    return this.put(
      `/organisations/${organisationId}/applicationperiods/${applicationPeriod.id}`,
      applicationPeriod,
    );
  }

  public async completeApplicationPeriod(
    organisationId: string,
    applicationPeriodId: string,
    settings: any,
  ): Promise<Response> {
    return this.post(`/organisations/${organisationId}/approvals/${applicationPeriodId}`, settings);
  }

  public async archiveApplicationPeriod(
    organisationId: string,
    applicationPeriodId: string,
  ): Promise<Response> {
    return this.post(
      `/organisations/${organisationId}/applicationperiods/${applicationPeriodId}/archive`,
      null,
    );
  }

  public async getUserSession(): Promise<UserSession> {
    return this.get<UserSession>('/session');
  }

  public async getUnapprovedAgreement(): Promise<AgreementItem> {
    return this.get<AgreementItem>('/session/unapproved-agreement');
  }

  public async approveAgreement(id: string): Promise<Response> {
    return this.post('/session/approve-agreement/', { id: id });
  }

  public async getOrganisations(): Promise<Organisation[]> {
    return this.get<Organisation[]>('/organisations');
  }

  public async getOrganisation(organisationId: string): Promise<Organisation> {
    return this.get<Organisation>(`/organisations/${organisationId}`);
  }

  public async updateOrganisation(organisation: Organisation): Promise<Response> {
    return this.put(`/organisations/${organisation.id}`, organisation);
  }

  public async createOrganisation(name: string): Promise<Response> {
    return this.post('/organisations', { name });
  }

  public async getBankAccounts(organisationId: string): Promise<BankAccount[]> {
    return this.get<BankAccount[]>(`/organisations/${organisationId}/bankaccounts`);
  }

  public async createBankAccount(
    organisationId: string,
    bankAccount: BankAccount,
  ): Promise<Response> {
    return this.post(`/organisations/${organisationId}/bankaccounts`, {
      name: bankAccount.name,
      description: bankAccount.description,
      iban: bankAccount.iban,
      bic: bankAccount.bic,
    });
  }

  public async updateBankAccount(
    organisationId: string,
    bankAccount: BankAccount,
  ): Promise<Response> {
    return this.put(`/organisations/${organisationId}/bankaccounts/${bankAccount.id}`, {
      name: bankAccount.name,
      description: bankAccount.description,
      iban: bankAccount.iban,
      bic: bankAccount.bic,
    });
  }

  public async deleteBankAccount(organisationId: string, bankAccountId: string): Promise<Response> {
    return this.delete(`/organisations/${organisationId}/bankaccounts/${bankAccountId}`);
  }

  public async getOrganisationUsers(organisationId: string): Promise<OrganisationUser[]> {
    return this.get<OrganisationUser[]>(`/organisations/${organisationId}/users`);
  }

  public async createOrganisationUser(
    organisationId: string,
    organisationUser: OrganisationUser,
  ): Promise<Response> {
    return this.post(`/organisations/${organisationId}/users`, {
      firstname: organisationUser.firstname,
      lastname: organisationUser.lastname,
      email: organisationUser.email,
      role: organisationUser.role,
    });
  }

  public async updateOrganisationUser(
    organisationId: string,
    organisationUser: OrganisationUser,
  ): Promise<Response> {
    return this.put(`/organisations/${organisationId}/users`, organisationUser);
  }

  public async deleteOrganisationUser(
    organisationId: string,
    organisationUserId: string,
  ): Promise<Response> {
    return this.delete(`/organisations/${organisationId}/users/${organisationUserId}`);
  }

  public async exportApplications(organisationId: string, model: any): Promise<Response> {
    const options = await this.getOptions('POST', JSON.stringify(model), 'application/xml');

    const response = await fetch(
      `${Api.baseUrl}${`/organisations/${organisationId}/exports/applications`}`,
      options,
    );

    return response;
  }

  public async downloadApplicationFile(
    organisationId: string,
    fileId: string,
  ): Promise<ArrayBuffer> {
    const options = await this.getOptions('GET', null, 'octet-stream/octet-stream');

    const response = await fetch(
      `${Api.baseUrl}${`/organisations/${organisationId}/downloads/${fileId}`}`,
      options,
    );
    if (!response.ok) {
      await handleError(response);
    }

    return response.arrayBuffer();
  }

  public async getUnpaidPayments(organisationId: string): Promise<any[]> {
    return this.get<any>(`/organisations/${organisationId}/payments/unpaid`);
  }

  public async getUnclaimedPayments(organisationId: string): Promise<any[]> {
    return this.get<any>(`/organisations/${organisationId}/payments/unclaimed`);
  }

  public async getPaymentBatch(organisationId: string, paymentBatchId: string): Promise<any[]> {
    return this.get<any>(`/organisations/${organisationId}/paymentbatches/${paymentBatchId}`);
  }

  public async getPaymentBatches(organisationId: string): Promise<any[]> {
    return this.get<any>(`/organisations/${organisationId}/paymentbatches`);
  }

  public async deletePaymentBatch(
    organisationId: string,
    paymentBatchId: string,
  ): Promise<Response> {
    return this.delete(`/organisations/${organisationId}/paymentbatches/${paymentBatchId}`);
  }

  public async downloadPaymentBatchFile(
    organisationId: string,
    paymentBatchId: string,
  ): Promise<Response> {
    const options = await this.getOptions('GET', null, 'application/xml');

    const response = await fetch(
      `${Api.baseUrl}${`/organisations/${organisationId}/paymentbatches/${paymentBatchId}/file`}`,
      options,
    );

    return response;
  }

  public async createPaymentBatch(organisationId: string, body: any): Promise<Response> {
    return this.post(`/organisations/${organisationId}/paymentbatches`, body);
  }

  public async downloadApplicationPdf(
    organisationId: string,
    applicationId: string,
  ): Promise<ArrayBuffer> {
    const options = await this.getOptions('GET', null, 'application/pdf');

    const response = await fetch(
      `${Api.baseUrl}${`/organisations/${organisationId}/downloads/applications/${applicationId}`}`,
      options,
    );
    if (!response.ok) {
      await handleError(response);
    }

    return response.arrayBuffer();
  }

  public async downloadApplicationPeriodForm(
    organisationId: string,
    applicationPeriodId: string,
  ): Promise<ArrayBuffer> {
    const options = await this.getOptions('GET', null, 'octet-stream/octet-stream');

    const response = await fetch(
      `${
        Api.baseUrl
      }${`/organisations/${organisationId}/applicationperiods/${applicationPeriodId}/form/download`}`,
      options,
    );
    if (!response.ok) {
      await handleError(response);
    }

    return response.arrayBuffer();
  }

  private async getUnauthenticated<T>(url: string): Promise<T> {
    const options = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    const response = await fetch(`${Api.baseUrl}${url}`, options);
    if (!response.ok) {
      await handleError(response);
    }
    return response.json();
  }

  private async get<T>(url: string): Promise<T> {
    const options = await this.getOptions('GET');
    const response = await fetch(`${Api.baseUrl}${url}`, options);
    if (!response.ok) {
      await handleError(response);
    }

    return response.json();
  }

  private async getWithPagination<T>(url: string): Promise<PagedResult<T>> {
    const options = await this.getOptions('GET');
    const response = await fetch(`${Api.baseUrl}${url}`, options);
    if (!response.ok) {
      await handleError(response);
    }

    const paginationHeader = await response.headers.get('X-Pagination');

    return {
      data: (await response.json()) as T[],
      pagination: paginationHeader ? JSON.parse(paginationHeader) : null,
    };
  }

  private async postFile(url: string, body: FileUpload | FormFileUpload): Promise<Response> {
    const options = await this.getFileUploadOptions('POST', body);
    const response = await fetch(`${Api.baseUrl}${url}`, options);

    return response;
  }

  private async post(url: string, body: any): Promise<Response> {
    const options = await this.getOptions('POST', JSON.stringify(body));
    try {
      const response = await fetch(`${Api.baseUrl}${url}`, options);
      if (!response.ok) {
        await handleError(response);
      }
      return response;
    } catch (error) {
      await handleError();
      throw error;
    }
  }

  private async postUnauthenticated(url: string, body: any): Promise<Response> {
    const options = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    };

    try {
      const response = await fetch(`${Api.baseUrl}${url}`, options);
      if (!response.ok) {
        await handleError(response);
      }
      return response;
    } catch (error) {
      await handleError();
      throw error;
    }
  }

  private getFileUploadOptions = async (method: string, body: FileUpload): Promise<any> => {
    const formData = new FormData();
    const { data, type, name } = body;
    const blob = new Blob([data], { type: type });
    formData.append('file', blob, name);

    const options = {
      method,
      headers: {
        Accept: 'application/json',
      },
      body: formData,
    } as any;

    const token = await this.getToken();

    options.headers.Authorization = `Bearer ${token}`;

    return options;
  };

  private async put(url: string, body: any): Promise<Response> {
    const options = await this.getOptions('PUT', JSON.stringify(body));
    const response = await fetch(`${Api.baseUrl}${url}`, options);

    if (!response.ok) {
      await handleError(response);
    }
    return response;
  }

  private async delete(url: string): Promise<Response> {
    const options = await this.getOptions('DELETE');
    const response = await fetch(`${Api.baseUrl}${url}`, options);
    if (!response.ok) {
      await handleError(response);
    }
    return response;
  }

  private async getOptions(
    method: string,
    body: any | null = null,
    accept = 'application/json',
  ): Promise<any> {
    const token = await this.getToken();

    const options = {
      method,
      headers: {
        Accept: accept,
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body,
    };

    return options;
  }

  private async getToken(): Promise<string> {
    // MSAL.js v2 exposes several account APIs, logic to determine which
    // account to use is the responsibility of the developer
    const account = this.instance.getAllAccounts()[0];
    const accessTokenRequest = {
      ...loginRequest,
      account,
    };

    try {
      const tokenResponse = await this.instance.acquireTokenSilent(accessTokenRequest);
      return tokenResponse.accessToken;
    } catch (error) {
      await this.instance.acquireTokenRedirect(loginRequest);
      throw error;
    }
  }
}

export const useApi = (): Api => {
  const { instance } = useMsal();
  return new Api(instance);
};
